import React from 'react'
import { Helmet } from 'react-helmet'

export default function Seo({title, description}) {
  return (
    <Helmet>
      <title>{title} | Manchester Tennis & Racquets Club</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}
